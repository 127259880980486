<template>
  <div class="recipient">
    <el-select v-model="value.recipient_type" size="mini" class="extraAccordion__row">
      <el-option v-for="item in listTypes" :key="item.type" :label="item.name" :value="item.type"></el-option>
    </el-select>
    <treeselect
      v-show="value.recipient_type === 'field'"
      :multiple="true"
      :options="dataTree"
      placeholder="Выбрать"
      class="miniTree"
      :normalizer="normalizer"
      :clear-on-select="true"
      :clearValueText="clearValueText"
      :noChildrenText="noChildrenText"
      :noResultsText="noResultsText"
      :limitText="limitText"
      :matchKeys="matchKeys"
      :limit="3"
      :value-consists-of="valueConsistsOf"
      v-model="value.notification_recipient_fields">
      <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName }" :class="labelClassName">
        {{ node.label }} (<b>id:{{node.id}}</b>)
      </label>
    </treeselect>
    <div v-show="value.recipient_type === 'state'">
      <el-select
        class="extraAccordion__row"
        value-key="id"
        multiple
        collapse-tags
        filterable
        v-model="value.notification_recipient_states"
        size="mini">
        <el-option
          v-for="item in listStatesAroundUser"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <el-select
        value-key="id"
        v-model="value.recipient_match_type"
        size="mini">
        <el-option
          v-for="item in listMathcType"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
    </div>
    <el-select
      v-show="value.recipient_type === 'user'"
      value-key="id"
      multiple
      collapse-tags
      filterable
      class="extraAccordion__row"
      v-model="value.notification_recipients "
      size="mini">
      <el-option
        v-for="item in fullName"
        :key="item.id"
        :label="item.surname + ' ' + item.name + ' ' + item.midname"
        :value="item.id">
      </el-option>
    </el-select>
  </div>
</template>

<script>
// import Users from '@/components/AccessEditor/Models/User'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

// API
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { UserAPI } from '@/services/AccessEditor/infrastructure/api/UserAPI'
export default {
  name: 'recipient',
  props: ['listStatesAroundUser', 'listUser', 'listField', 'value', 'listMathcType'],
  components: { Treeselect },
  data () {
    return {
      listUserEmail: [],
      dataTree: [],
      listTypes: [
        {
          type: 'field',
          name: this.$locale.logic_editor.routes.field
        },
        {
          type: 'state',
          name: this.$locale.logic_editor.routes.states
        },
        {
          type: 'user',
          name: this.$locale.logic_editor.routes.list
        }
      ],
      matchKeys: ['name', 'id'],
      valueConsistsOf: 'LEAF_PRIORITY',
      clearValueText: 'Очистить',
      noChildrenText: 'Нет данных',
      noResultsText: 'Не найдено',
      limitText: count => `и еще ${count}`,
      normalizer (node) {
        return {
          id: node.id,
          label: node.name,
          children: node.children,
          isLeaf: node.leaf
        }
      }
    }
  },
  computed: {
    // fullName () {
    //   let filterEmail = this.listUserEmail
    //     .map((el) => {
    //       let name = (el.name !== '' && el.name !== null) ? el.name.substring(0, 1) : el.name = ''
    //       let midname = (el.midname !== '' && el.midname !== null) ? el.midname.substring(0, 1) : el.midname = ''
    //       let surname = (el.surname !== '' && el.surname !== null) ? el.surname : el.surname = ''
    //       return {
    //         id: el.id,
    //         surname: surname,
    //         name: name,
    //         midname: midname
    //       }
    //     })

    //   return filterEmail
    // }
    fullName () {
      let arr = this.listUserEmail.map(el =>
        ({
          id: el.id,
          surname: el.surname !== null ? el.surname : '',
          name: el.name !== null ? el.name : '',
          midname: el.midname !== null ? el.midname : ''
        })
      )
      return arr
    }
  },
  watch: {
    'value.recipient_type': function (newVal, oldval) {
      if (oldval !== null) {
        this.$nextTick().then(() => {
          this.value.notification_recipient_states = null
          this.value.notification_recipients = null
        })
      }
    }
  },
  methods: {
    // поиск по дереву имя поля
    deepSearch (arr) {
      for (let el of arr) {
        if (el.leaf) delete el.children
        // if (!el.leaf) el.isDisabled = true
        if (el.children instanceof Array) this.deepSearch(el.children)
      }
      return arr
    }
  },
  async mounted () {
    // this.listUserEmail = await Users.params({ fields: 'id,name,surname,midname,email' }).$get()
    try {
      this.listUserEmail = await APIClient.shared.request(new UserAPI.GetUsers([]))
    } catch (error) {
      console.log({ error })
    }
    this.dataTree = this.deepSearch(this.listField)
  }
}
</script>

<style>
.recipient .extraAccordion__row {
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 0px;
  margin-top: 0px;
}
.recipient .miniTree .vue-treeselect__multi-value {
  font-size: 12px;
}
</style>
